'use client';
/* eslint-disable react/no-unstable-nested-components */

import * as React from 'react';
import { ChevronLeft, ChevronRight, CircleSlash } from 'lucide-react';
import { Button, DayPicker, DayProps, useDayRender } from 'react-day-picker';
import { buttonVariants } from '..';
import { cn, isSameDate } from '@av/utils';
import { nl } from 'date-fns/locale';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@av/ui/form';

export type CustomBlockedDate = { dates: Date[]; message: string };
export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  customBlockedDates?: CustomBlockedDate[];
};

export function Day(props: DayProps & { customBlockedDates?: CustomBlockedDate[] }): JSX.Element {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

  if (dayRender.isHidden) {
    return <div role="gridcell" />;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  const isBlocked = props.customBlockedDates?.find((customBlockedDate) =>
    customBlockedDate.dates.some(
      (blockedDate) => blockedDate && isSameDate(blockedDate, props.date)
    )
  );
  if (isBlocked) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="flex size-full items-center justify-center">
            <CircleSlash className="size-5 text-validation" />
          </TooltipTrigger>
          <TooltipContent className="max-w-xs">
            <p>{isBlocked.message}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return <Button name="day" ref={buttonRef} {...dayRender.buttonProps} />;
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  customBlockedDates,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-3 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-between pl-2.5 pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-4 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-gray-200'
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white',
        day_today: 'bg-gray-200 text-accent-foreground',
        day_outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
        Day: (props) => <Day {...props} customBlockedDates={customBlockedDates} />,
      }}
      locale={nl}
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
