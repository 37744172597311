'use client';

import * as React from 'react';
import { X } from 'lucide-react';

import { Badge } from '../badge';
import { Command, CommandGroup, CommandItem, CommandList } from '../command';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '@av/utils';

export function MultiSelect({
  options,
  value = [],
  onChange,
  disabled,
  minChars = 0,
}: {
  options: { value: number; label: string }[];
  value: number[];
  onChange: (v: number[]) => void;
  disabled?: boolean;
  minChars?: number;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const selected = options.filter((o) => value.includes(o.value));

  const handleUnselect = React.useCallback(
    (v: number) => {
      onChange(value.filter((s) => s !== v));
    },
    [value]
  );

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === 'Escape') {
        input.blur();
      }
    }
  }, []);

  const selectables = React.useMemo(
    () => options.filter((option) => !selected.includes(option)),
    [options, selected]
  );

  return (
    <Command className="overflow-visible bg-transparent" onKeyDown={handleKeyDown}>
      <div
        className={cn(
          'flex min-h-14 w-full rounded-md border bg-white px-5 py-3 text-base ring-offset-background placeholder:text-muted-fg placeholder:font-normal font-normal focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          disabled ? 'opacity-50 pointer-events-none' : ''
        )}
      >
        <div className="flex flex-wrap gap-2 items-center w-full">
          {selected.map((option) => {
            return (
              <Badge className="h-min py-1" key={option.value} variant="secondary">
                {option.label}
                <button
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onClick={() => handleUnselect(option.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option.value);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            );
          })}
          <CommandPrimitive.Input
            className="flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onValueChange={setInputValue}
            placeholder={`Typ om te zoeken${minChars ? ` (min ${minChars})` : ''}`}
            ref={inputRef}
            value={inputValue}
          />
        </div>
      </div>
      <div className="relative mt-2">
        <CommandList>
          {open && selectables.length > 0 && inputValue?.length >= minChars ? (
            <div className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
              <CommandGroup className="h-full overflow-auto">
                {selectables.map((v) => {
                  return (
                    <CommandItem
                      className="cursor-pointer"
                      data-disabled={undefined}
                      key={v.value}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');
                        onChange([...value, v.value]);
                      }}
                    >
                      {v.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  );
}
